import React, { useEffect, useState } from "react";
import axios from "../axiosInstance";
import { Table, Button, Form, Pagination } from "react-bootstrap";
import AddMenuItemModal from "./AddMenuItemModal";
import AssignMenuModal from "./AssignMenuModal";
import EditMenuItemModal from "./EditMenuItemModal";
import ViewSubMenuModal from "./ViewSubMenuModal";
import {
  FaPlusCircle,
  FaRegPlusSquare,
  FaUserCircle,
  FaEdit,
  FaEye,
  FaTrashAlt,
} from "react-icons/fa";
import { FaChevronCircleDown } from "react-icons/fa";
import Swal from "sweetalert2";

const MenuItemsTable = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [subMenuItems, setSubMenuItems] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewSubMenuModal, setShowViewSubMenuModal] = useState(false);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentItems = filteredMenuItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    fetchMenuItems();
  }, []);
  useEffect(() => {
    handleSearch(searchTerm);
  }, [menuItems, searchTerm]);

  const handleSearch = (term) => {
    const filtered = menuItems.filter((item) =>
      item.title.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredMenuItems(filtered);
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get("/api/menuitems");
      setMenuItems(response.data);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const fetchSubMenuItems = async (parentId) => {
    try {
      const response = await axios.get(`/api/submenuitems/${parentId}`);
      setSubMenuItems(response.data);
      setShowViewSubMenuModal(true);
    } catch (error) {
      console.error("Error fetching submenu items:", error);
    }
  };

  const handleAddModalOpen = (isSubMenu, parentId = null) => {
    setIsSubMenu(isSubMenu);
    setParentId(parentId);
    setShowAddModal(true);
  };

  const handleAddModalClose = () => {
    setShowAddModal(false);
  };

  const handleAssignModalOpen = () => {
    setShowAssignModal(true);
  };

  const handleAssignModalClose = () => {
    setShowAssignModal(false);
  };

  const handleEditModalOpen = (menuItem) => {
    setCurrentMenuItem(menuItem);
    setShowEditModal(true);
    fetchMenuItems();
  };
  const handleDeleteMenuItem = async (itemId) => {
    try {
      await axios.delete(`api/menuitem/${itemId}`);
      fetchMenuItems();
    } catch (error) {
      console.error("Error deleting menu item:", error);
    }
  };
  const handleEditModalClose = () => {
    setShowEditModal(false);
    setCurrentMenuItem(null);
  };

  // const handleDeleteMenuItem = async (itemId) => {
  //   try {
  //     await axios.delete(`api/menuitem/${itemId}`);
  //     fetchMenuItems(); 
  //   } catch (error) {
  //     console.error("Error deleting menu item:", error);
  //   }
  // };
  const confirmDelete = (itemId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this menu item!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteMenuItem(itemId); 
        Swal.fire('Deleted!', 'Your menu item has been deleted.', 'success');
      }
    });
  };
  const handleViewSubMenu = (parentId) => {
    fetchSubMenuItems(parentId);
  };
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedItems = [...filteredMenuItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "desc" ? 1 : -1;
      }
      return 0;
    });
    setFilteredMenuItems(sortedItems);
  };

  return (
    <div className="container p-6">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Button variant="primary" onClick={() => handleAddModalOpen(false)}>
          <div className="d-flex justify-content-between gap-2 align-items-center mb-1">
            <FaPlusCircle /> <b>Menu Item</b>
          </div>
        </Button>
        <Button
          variant="secondary"
          onClick={handleAssignModalOpen}
          className="ms-2"
        >
          <div className="d-flex justify-content-between gap-2 align-items-center mb-1">
            <FaRegPlusSquare />
            <b>Menu to</b> <FaUserCircle />
          </div>
        </Button>
      </div>
      {/* Search Input */}
      <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '50px'}}>
                      <i className="fas fa-search"></i> 
                    </span>
                  </div>
      <Form.Control
        type="text"
        placeholder="Search .........."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      </div>
      </div>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th
              className="d-flex justify-content-between gap-2"
              onClick={() => handleSort("title")}
            >
              <b>Title</b>
              <FaChevronCircleDown />
            </th>
            <th onClick={() => handleSort("url")}>
              <b>URL</b>
              <FaChevronCircleDown />
            </th>
            <th onClick={() => handleSort("icon")}>
              <b>Icon</b>
              <FaChevronCircleDown />
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {currentItems.map((menuItem) => (
    <tr key={menuItem.id}>
      <td>{menuItem.title}</td>
      <td>{menuItem.url}</td>
      <td>{menuItem.icon}</td>
      <td>
      {menuItem.url === '#' && (
        <Button
          variant="info"
          onClick={() => handleViewSubMenu(menuItem.id)}
          className="ms-2"
          title="View Submenus"
        >
          <FaEye style={{ color: "#fff" }} />
        </Button>
      )}

        
        <Button
          variant="success"
          onClick={() => handleAddModalOpen(true, menuItem.id)}
          className="ms-2"
          title="Add Submenu"
        >
          <FaPlusCircle />
        </Button>
        <Button
          variant="warning"
          onClick={() => handleEditModalOpen(menuItem)}
          className="ms-2"
          title="Edit"
        >
          <FaEdit style={{color: "#fff"}}/>
        </Button>
        <Button
          variant="danger"
          onClick={() => confirmDelete(menuItem.id)}
          className="ms-2"
          title="Delete"
        >
          <FaTrashAlt />
        </Button>
      </td>
    </tr>
  ))}
</tbody>

      </Table>
      <Pagination className="d-flex justify-content-end gap-2 align-items-center mt-0">
        {Array.from(
          { length: Math.ceil(filteredMenuItems.length / itemsPerPage) },
          (_, idx) => (
            <Pagination.Item
              key={idx + 1}
              active={idx + 1 === currentPage}
              onClick={() => handlePagination(idx + 1)}
            >
              {idx + 1}
            </Pagination.Item>
          )
        )}
      </Pagination>

      <AddMenuItemModal
        show={showAddModal}
        onHide={handleAddModalClose}
        isSubMenu={isSubMenu}
        parentId={parentId}
        reloadMenuData={fetchMenuItems}
      />
      <AssignMenuModal
        show={showAssignModal}
        onHide={handleAssignModalClose}
        reloadMenuData={fetchMenuItems}
      />
      <EditMenuItemModal
        show={showEditModal}
        onHide={handleEditModalClose}
        menuItem={currentMenuItem}
        reloadMenuData={fetchMenuItems}
      />
      <ViewSubMenuModal
        show={showViewSubMenuModal}
        onHide={() => setShowViewSubMenuModal(false)}
        subMenuItems={subMenuItems}
        handleEdit={handleEditModalOpen}
        handleDelete={handleDeleteMenuItem}
      />
    </div>
  );
};

export default MenuItemsTable;
