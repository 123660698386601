import React,{useState} from "react";
import Sidebar from "./components/Sidebar";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./components/all.css";
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";
import Footer from "./components/Footer";
import "./components/css/style.css";
import "./components/css/bootstrap.min.css";
import "./components/js/mains";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import BackToTopButton from "./BackToTopButton";
import "./index.css";
function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };
  return (
    <div className="d-flex vh-100 w-100">
      <Sidebar userId={localStorage.getItem("userId")} isSidebarOpen={isSidebarOpen} />
      <div className="content flex-grow-1 d-flex flex-column">
      <Navbar toggleSidebar={toggleSidebar} />
        <Dashboard />
        <Footer />
      </div>
      <BackToTopButton />
    </div>
  );
}

export default App;
