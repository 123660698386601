import React, { useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const NoPermission = () => {
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    MySwal.fire({
      icon: 'error',
      title: 'Access Denied',
      text: 'You do not have permission to access this page.',
      confirmButtonText: 'Go to Dashboard',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Dashbord";  // Redirect to dashboard
      }
    });
  }, []);

  return null; 
};

export default NoPermission;
