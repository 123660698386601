import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "./axiosInstance";
import "./index.css";
import App from "./App";
import LoginPage from "./components/LoginPage";
import NotFound from "./components/NotFound";
import NoPermission from "./components/NoPermission";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Purchaseitems from "./RunPages/PurchaseItems";
import ManuMAnagement from "./RunPages/ManuMAnagement";
import StocksManagement from "./RunPages/Stocks";
import UsersManagement from "./RunPages/Users";
import Items from "./RunPages/Items";
import Itemstock from "./RunPages/Itemstock";
import Itemoutstock from "./RunPages/Itemoutstock";
import Suppliers from "./RunPages/Suppliers";
import Customers from "./RunPages/Customers";
import Deliverpage from "./RunPages/Deliverpage";
import OrderManagement from "./RunPages/Orders";
import Roles from "./RunPages/Roles";
import ProtectedRoute from "./components/ProtectedRoute";

// Root app component
const Root = () => {
  const [permissions, setPermissions] = useState({});
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (!userId) {
      console.error("No userId found in localStorage");
      setLoading(false);
      return;
    }
    // Fetch menu and permissions
    axios
      .get(`/api/menu/${userId}`)
      .then((response) => {
        setMenuData(response.data.menuData);
        setPermissions(response.data.routePermissions);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching permissions:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LoginPage />} />
        <Route path="" element={<LoginPage />} />
        <Route path="/no-permission" element={<NoPermission />} />
        <Route path="/items-out-stock" element={<Itemoutstock />} />
        <Route path="/Dashbord" element={<App />} />


        {/* Protected Routes */}
        <Route
          path="/purchase_items"
          element={
            <ProtectedRoute hasPermission={permissions["/purchase_items"]}>
              <Purchaseitems />
            </ProtectedRoute>
          }
        />
        <Route
          path="/stocks"
          element={
            <ProtectedRoute hasPermission={permissions["/stocks"]}>
              <StocksManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage_order"
          element={
            <ProtectedRoute hasPermission={permissions["/manage_order"]}>
              <OrderManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/items"
          element={
            <ProtectedRoute hasPermission={permissions["/items"]}>
              <Items />
            </ProtectedRoute>
          }
        />
        <Route
          path="/items-in-stock"
          element={
            <ProtectedRoute hasPermission={permissions["/items-in-stock"]}>
              <Itemstock />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Users"
          element={
            <ProtectedRoute hasPermission={permissions["/Users"]}>
              <UsersManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/roles"
          element={
            <ProtectedRoute hasPermission={permissions["/roles"]}>
              <Roles />
            </ProtectedRoute>
          }
        />
        <Route
          path="/delivery"
          element={
            <ProtectedRoute hasPermission={permissions["/delivery"]}>
              <Deliverpage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/suppliers"
          element={
            <ProtectedRoute hasPermission={permissions["/suppliers"]}>
              <Suppliers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <ProtectedRoute hasPermission={permissions["/customers"]}>
              <Customers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Menu-Assign-Management"
          element={
            <ProtectedRoute hasPermission={permissions["/Menu-Assign-Management"]}>
              <ManuMAnagement />
            </ProtectedRoute>
          }
        />

        {/* Catch-all for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
