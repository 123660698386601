import { useState, useEffect } from "react";

// HOC for handling the role state
const usersRole = (WrappedComponent) => {
  return (props) => {
    const [role, setRole] = useState("");

    useEffect(() => {
      const userRole = localStorage.getItem("role");
      setRole(userRole);
    }, []);

    return <WrappedComponent role={role} {...props} />;
  };
};

export default usersRole;
