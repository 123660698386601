import React, { useState, useEffect } from "react";
import axios from "../axiosInstance"; 
import usersRole from "../UsersRole";
import Select from "react-select";
import debounce from "lodash.debounce"; 
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, Form, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import AddSupplierModal from "./AddSupplierModal";
import { VscTrash, VscFilePdf } from "react-icons/vsc";
import { FaEye } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import imgHeader from "../assets/header.jpg"
import imgFooter from "../assets/footer.jpg"
const PurchaseItemForm = (role) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [purchasesPerPage] = useState(10);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierName, setSupplierName] = useState("");
  const [stocks, setStock] = useState([]);
  const [stockId, setstockId] = useState("");
  const [purchasedate, setPurchasedate] = useState("");
  const [comments, setComments] = useState("");
  const [purchases, setPurchases] = useState([]);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [payNow, setPayNow] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [showPaymentHistoryModal, setShowPaymentHistoryModal] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [showPayNowModal, setShowPayNowModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [amountpaid, setAmountPaid] = useState('');
  const [pay_mode, setPayMode] = useState('');
  const [pay_date, setPayDate] = useState(new Date().toISOString().split('T')[0]);
  const handleClose = () => setShowPurchaseModal(false);
  const handleShow = () => setShowPurchaseModal(true);

  useEffect(() => {
    axios.get("/api/items").then((response) => setItems(response.data));
    axios
      .get("/api/stocks")
      .then((response) => setStock(response.data));
    axios.get("/api/allpurchases").then((response) => setPurchases(response.data));
    axios
      .get("/api/accounts")
      .then((response) => setAccounts(response.data))
      .catch((error) => console.error("Error fetching accounts:", error));
  }, []);

  useEffect(() => {
    if (supplierName) {
      fetchSuppliers(supplierName);
    } else {
      setSuppliers([]);
    }
  }, [supplierName]);

  useEffect(() => {
    setPurchasedate(today);
  }, [today]);
  const fetchAllSuppliers = () => {
    axios
      .get("/api/suppliers") // Fetch all suppliers or a subset based on your needs
      .then((response) => {
        const supplierOptions = response.data.map((s) => ({
          value: s.supplier_id,
          label: s.fname,
        }));
        setSuppliers(supplierOptions);
      })
      .catch((error) => console.error("Error fetching suppliers:", error));
  };
  useEffect(() => {
    const fetchPurchases = async () => {
      const managersRes = await axios.get("/api/allpurchases");
      setPurchases(managersRes.data);
    }}, []);
  const fetchSuppliers = debounce((supplier_name) => {
    axios
      .get("/api/suppliers/search", { params: { query: supplier_name } })
      .then((response) => {
        const supplierOptions = response.data.map((s) => ({
          value: s.supplier_id,
          label: s.supplier_name,
        }));
        setSuppliers(supplierOptions);
      })
      .catch((error) => console.error("Error fetching suppliers:", error));
  }, 300);

  const handleAddItem = () => {
    setSelectedItems([
      ...selectedItems,
      { item_id: "", name: "", isNew: false, req_qty: 1, req_unityPrice: 0 },
    ]);
  };

  const handleItemChange = (index, key, value) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index][key] = value;
  
    if (key === "item_id") {
      const selectedItem = itemOptions.find((item) => item.value === value);
      if (selectedItem) {
        newSelectedItems[index].item_id = selectedItem.value;
        newSelectedItems[index].name = selectedItem.label;
      }
    }
  
    setSelectedItems(newSelectedItems);
  };
  
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: '100%',
      zIndex: 9999, 
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '300px',
      overflowY: 'auto', 
    }),
    singleValue: (provided) => ({
      ...provided,
      maxWidth: '300px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  };
  const handleItemSelect = (selectedOption, index) => {
    // Handle both selected and new item cases
    if (selectedOption) {
      handleItemChange(index, "item_id", selectedOption.value);
    } else {
      handleItemChange(index, "item_id", '');
    }
  };
  
  // Convert items to the format expected by react-select
  const itemOptions = items.map((it) => ({
    value: it.item_id,
    label: it.item_name,
  }));

  const handleRemoveItem = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This item will be removed from the list.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const newSelectedItems = selectedItems.filter((_, i) => i !== index);
        setSelectedItems(newSelectedItems);
        Swal.fire({
          title: "Removed!",
          text: "The item has been removed.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  const handleSupplierChange = (selectedOption) => {
    setSelectedSupplier(selectedOption);
    setSupplierName(selectedOption ? selectedOption.label : "");
  };

  const handleSupplierInputChange = (inputValue) => {
    setSupplierName(inputValue);
  };
  const handleSupplierBlur = () => {
    if (!supplierName) return;
    const existingSupplier = suppliers.find(
      (s) => s.label.toLowerCase() === supplierName.toLowerCase()
    );

    if (existingSupplier) {
      setSelectedSupplier(existingSupplier);
    } else {
      setShowModal(true); // Show the modal if the supplier is not found
    }
  };
  const handleSaveNewSupplier = (name) => {
    setLoading(true);
    axios
      .post("/api/suppliers", { name })
      .then((response) => {
        const newSupplier = { value: response.data.id, label: name };
        setSuppliers([...suppliers, newSupplier]);
        setSelectedSupplier(newSupplier);
        setShowModal(false); // Close the modal after saving
      })
      .catch((error) => console.error("Error creating supplier:", error))
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!selectedSupplier) {
      Swal.fire("Error", "Please select a supplier", "error");
      return;
    }
  
    // Show SweetAlert confirmation dialog
    Swal.fire({
      title: 'Confirm Purchase',
      text: "Do you want to pay now or later?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Pay Now',
      cancelButtonText: 'Later',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked "Pay Now"
        setShowPayNowModal(true);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked "Later"
        const purchaseData = {
          user_order_by: user.id,
          supplier_id: selectedSupplier.value,
          purchasedate,
          items: selectedItems,
          stockId,
          comments,
          amountpaid: 0,
          pay_mode: '',  
          pay_date: ''    
        };
  
        axios
          .post("/api/purchase-items", purchaseData)
          .then(() => {
            Swal.fire("Success!", "Purchase created successfully!", "success");
            axios
              .get("/api/allpurchases")
              .then((response) => setPurchases(response.data));
            setShowPurchaseModal(false);
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              "There was an error creating the purchase.",
              "error"
            );
            console.error("Error creating purchase:", error);
          });
      }
    });
  };
  
  // Handle PayNowModal form submit
  const handlePayNowSubmit = (e) => {
    e.preventDefault();
  
    const paymentData = {
      amountpaid,
      pay_mode,
      pay_date,
    };
  
    const purchaseData = {
      user_order_by: user.id,
      supplier_id: selectedSupplier.value,
      purchasedate,
      items: selectedItems,
      comments,
      ...paymentData, // Add payment data
    };
  
    axios
      .post("/api/purchase-items", purchaseData)
      .then(() => {
        Swal.fire("Success!", "Purchase created successfully!", "success");
        axios
          .get("/api/allpurchases")
          .then((response) => setPurchases(response.data));
        setShowPayNowModal(false);
        setShowPurchaseModal(false);
      })
      .catch((error) => {
        Swal.fire(
          "Error!",
          "There was an error creating the purchase.",
          "error"
        );
        console.error("Error creating purchase:", error);
      });
  };
  
  const handleShowDetailModal = (purchase_id) => {
    axios
      .get(`/api/purchases/${purchase_id}`)
      .then((response) => {
        setSelectedPurchase(response.data);
        setShowDetailModal(true);
      })
      .catch((error) =>
        console.error("Error fetching purchase details:", error)
      );
  };
  const handleShowPaymentHistoryModal = (purchaseId) => {
    axios
      .get(`/api/purchase/${purchaseId}/payments`)
      .then((response) => {
        setPaymentHistory(response.data);
        setShowPaymentHistoryModal(true);
      })
      .catch((error) =>
        console.error("Error fetching payment history:", error)
      );
  };

  const handleClosePaymentHistoryModal = () => {
    setShowPaymentHistoryModal(false);
    setPaymentHistory([]);
  };

  const handleCloseDetailModal = () => setShowDetailModal(false);

  const handleShowPaymentModal = () => setShowPaymentModal(true);

  const handleClosePaymentModal = () => setShowPaymentModal(false);
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePaymentAmountChange = (e) => {
    setPaymentAmount(e.target.value);
  };

  const handleRecordPayment = () => {
    if (!paymentMethod || !paymentAmount || !selectedPurchase) {
      Swal.fire("Error", "Please fill out all fields", "error");
      return;
    }

    const paymentData = {
      purchase_id: selectedPurchase.purchase.purchase_id, // Correct key for the backend
      method: paymentMethod,
      amount: parseFloat(paymentAmount),
    };

    // Display the data being sent to the backend
    Swal.fire({
      title: "Data to be sending........",
      icon: "info",
      showCancelButton: true, // Allow the user to cancel the request
      confirmButtonText: "Send Payment",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post("/api/payments", paymentData)
          .then(() => {
            Swal.fire("Success", "Payment recorded successfully", "success");
            handleClosePaymentModal();
            // Refresh purchases list after successful payment
            axios
              .get("/api/allpurchases")
              .then((response) => setPurchases(response.data))
              .catch((error) => {
                console.error("Error fetching purchases:", error);
                alert(paymentData);
              });
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "There was a problem recording the payment",
              "error"
            );
            console.error("Error recording payment:", error);
          });
      }
    });
  };
  const handleDeletePurchase = (purchase_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This purchase will be permanently deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/api/purchase-items/${purchase_id}`)
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "The purchase has been deleted.",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
            // Refresh the purchase list after deletion
            setPurchases(purchases.filter((purchase) => purchase.purchase_id !== purchase_id));
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "There was a problem deleting the purchase.",
              "error"
            );
            console.error("Error deleting purchase:", error);
          });
      }
    });
  };
  const handleDownload = async () => {
    const doc = new jsPDF();
    // Add Header
    doc.addImage(imgHeader, "PNG", 10, 10, 190, 30); // Adjust dimensions as needed
    doc.setFontSize(16);
    doc.text("Purchase Details", 105, 50, { align: "center" });

    // Add Purchase Info
    doc.setFontSize(12);
    doc.text(`Purchase Number: ${selectedPurchase.purchase.purchase_id}`, 10, 60);
    doc.text(`Ordered By: ${selectedPurchase.purchase.user_name}`, 10, 70);
    doc.text(`Supplier Name: ${selectedPurchase.purchase.supplier_name}`, 10, 80);
    doc.text(
      `Date: ${new Date(selectedPurchase.purchase.purchase_date).toLocaleDateString()}`,
      10,
      90
    );

    // Add Table Headers
    let yOffset = 100;
    doc.text("Item Details", 10, yOffset);
    yOffset += 10;

    doc.autoTable({
      startY: yOffset,
      head: [["Name", "Quantity", "Unit Price", "Total"]],
      body: selectedPurchase.details.map((detail) => [
        detail.name,
        detail.req_qty,
        detail.req_unityPrice,
        (detail.req_qty * detail.req_unityPrice).toFixed(2),
      ]),
    });

    // Add Total Amount, Paid Amount, Remaining Amount
    const totalAmount = selectedPurchase.details.reduce(
      (total, detail) => total + detail.req_qty * detail.req_unityPrice,
      0
    );
    const paidAmount = selectedPurchase.paymentDetails.total_paid;
    const remainingAmount = totalAmount - paidAmount;

    yOffset = doc.lastAutoTable.finalY + 10;
    doc.text(`Total Amount: ${totalAmount} Frw`, 10, yOffset);
    yOffset += 10;
    doc.text(`Paid Amount: ${paidAmount} Frw`, 10, yOffset);
    yOffset += 10;
    doc.text(`Remaining Amount: ${remainingAmount} Frw`, 10, yOffset);

    // Add Footer Image
    yOffset += 20;
    doc.addImage(imgFooter, "PNG", 10, yOffset, 190, 30); // Adjust dimensions as needed

    // Download the PDF
    doc.save(`Purchase_Details_${selectedPurchase.purchase.purchase_id}.pdf`);
  };
// Assuming searchTerm is the state for the search input field

const handleFilterChange = (status) => {
  setFilter(status);
  setCurrentPage(1); // Reset to first page on filter change
};
const [filter, setFilter] = useState('ALL');
  // Filter purchases based on selected filter
  const filteredPurchases = purchases.filter((purchase) => {
    if (filter === 'ALL') return true;
    return purchase.purchase_status === filter;
  }).filter((purchase) =>
  (purchase.purchase_id && purchase.purchase_id.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
  (purchase.supplier_name && purchase.supplier_name.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
  (purchase.purchase_date && new Date(purchase.purchase_date).toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase())) ||
  (purchase.user_name && purchase.user_name.toString().toLowerCase().includes(searchTerm))

  );

  // Pagination
  const indexOfLastPurchase = currentPage * purchasesPerPage;
  const indexOfFirstItem = indexOfLastPurchase - purchasesPerPage;
  const currentPurchases = filteredPurchases.slice(indexOfFirstItem, indexOfLastPurchase);

  // Total Pages
  const totalPages = Math.ceil(filteredPurchases.length / purchasesPerPage);

  // Calculate totals
  const totalCost = currentPurchases.reduce((total, purchase) => total + parseFloat(purchase.total_cost || 0), 0);
  const totalPaidAmount = currentPurchases.reduce((total, purchase) => total + parseFloat(purchase.total_paid_amount || 0), 0);
  const totalRemainingAmount = currentPurchases.reduce(
    (total, purchase) => total + (parseFloat(purchase.total_cost || 0) - parseFloat(purchase.total_paid_amount || 0)),
    0
  );
  return (
    <div>
      {/* Modal for the Form */}
      <Modal show={showPurchaseModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className="p-4">
            {/* Supplier Field */}
            <Form.Group controlId="supplierName">
              <Form.Label>Supplier</Form.Label>
              <Select
                value={selectedSupplier}
                onChange={handleSupplierChange}
                onInputChange={handleSupplierInputChange}
                onFocus={fetchAllSuppliers}
                options={suppliers}
                isLoading={loading}
                placeholder="Select a supplier or type to search..."
              />
            </Form.Group>
            <AddSupplierModal
              show={showModal}
              handleClose={() => setShowModal(false)}
              handleSave={handleSaveNewSupplier}
            />
            {/* Date Field */}
            <Form.Group controlId="date">
              <Form.Label>Date:</Form.Label>
              <Form.Control
                type="date"
                value={purchasedate}
                onChange={(e) => setPurchasedate(e.target.value)}
                required
              />
            </Form.Group>
            {/* Items Table */}
            <Table striped bordered hover className="mt-4">
  <thead>
    <tr>
      <th>Item</th>
      <th>Quantity</th>
      <th>Unit Price</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    {selectedItems.map((item, index) => (
      <tr key={index}>
        <td>
          {/* Display the search field or item select */}
          {item.isNew ? (
            <Form.Control
              type="text"
              placeholder="Enter item name"
              value={item.name}
              onChange={(e) =>
                handleItemChange(index, "name", e.target.value)
              }
              required
            />
          ) : (
                <Select
                  value={item.item_id ? itemOptions.find(option => option.value === item.item_id) : null}
                  onChange={(selectedOption) => handleItemSelect(selectedOption, index)}
                  options={itemOptions.filter(option => !selectedItems.some(selected => selected.item_id === option.value))} // Filter out already selected items
                  placeholder="Select or search item"
                  isClearable
                  isSearchable
                  noOptionsMessage={() => "No items found"}
                  styles={customStyles}
                />

          )}
        </td>
        <td>
          <Form.Control
            type="number"
            value={item.req_qty}
            onChange={(e) =>
              handleItemChange(index, "req_qty", e.target.value)
            }
            required
            min="1"
          />
        </td>
        <td>
          <Form.Control
            type="number"
            value={item.req_unityPrice}
            onChange={(e) =>
              handleItemChange(index, "req_unityPrice", e.target.value)
            }
            required
            min="0"
          />
        </td>
        <td>
          <Button
            variant="danger"
            onClick={() => handleRemoveItem(index)}
          >
            Remove
          </Button>
        </td>
      </tr>
    ))}
  </tbody>
</Table>

            <Button variant="primary" onClick={handleAddItem}>
              Add Item
            </Button>
            <Form.Group controlId="comments" className="mt-4">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </Form.Group>
            {/* Submit Button */}
            <br />
            {user && (
            <input type="hidden" name="user_order_by" value={user.id} />
          )}
            <Button
              type="submit"
              className="mt-4"
              variant="success"
              disabled={!selectedItems.length}
            >
              Create Purchase
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* PayNowModal */}
    <Modal show={showPayNowModal} onHide={() => setShowPayNowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Pay Now</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handlePayNowSubmit}>
          <Form.Group controlId="amountpaid">
            <Form.Label>Amount Paid</Form.Label>
            <Form.Control
              type="number"
              value={amountpaid}
              onChange={(e) => setAmountPaid(e.target.value)}
              required
              min="0"
            />
          </Form.Group>
          <Form.Group controlId="pay_mode">
            <Form.Label>Payment Method</Form.Label>
            <Form.Control
              as="select"
              value={pay_mode}
              onChange={(e) => setPayMode(e.target.value)}
              required
            >
             <option value="">Select Payment Method</option>
                {accounts.map((account) => (
                  <option key={account.id} value={account.account_name}>
                    {account.account_name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="pay_date">
            <Form.Label>Payment Date</Form.Label>
            <Form.Control
              type="date"
              value={pay_date}
              onChange={(e) => setPayDate(e.target.value)}
              required
              min={today}
            />
          </Form.Group>

          <Button type="submit" variant="primary">
            Confirm Payment
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
      {/* Purchase Table Component */}
      <div className="mt-1">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>Purchase List</h2>
          <Button variant="primary" className="mb-4" onClick={handleShow}>
            Add New Purchase
          </Button>
        </div>
        <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '50px'}}>
                      <i className="fas fa-search"></i> 
                    </span>
                  </div>
      <Form.Control
        type="text"
        placeholder="Search .........."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      </div>
      </div>
       <div className="mb-3">
        <Button variant="primary" onClick={() => handleFilterChange('ALL')}>ALL</Button>
        <Button variant="warning" onClick={() => handleFilterChange('In Progress')} className="mx-2">IN PROGRESS</Button>
        <Button variant="success" onClick={() => handleFilterChange('Complete')}>COMPLETE</Button>
      </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Purchase Number</th>
              <th>Supplier Name</th>
              <th>Purchase Date</th>
              <th>User Order</th>
              <th>Amount Due</th>
              <th>Amount Paid</th>
              <th>Amount Reamin</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
  {currentPurchases.map((purchase) => (
    <tr key={purchase.purchase_id}>
      <td>{purchase.purchase_id}</td>
      <td>{purchase.supplier_name}</td>
      <td>{new Date(purchase.purchase_date).toLocaleDateString()}</td>
      <td>{purchase.user_name}</td>
      
      {/* Format total_cost in FRW */}
      <td style={{ color: '#28a745', fontWeight: 800 }}>
        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(purchase.total_cost)}
      </td>

      {/* Format total_paid_amount in FRW */}
      <td style={{ color: '#28a745', fontWeight: 800 }}>
        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(purchase.total_paid_amount)}
      </td>

      {/* Calculate and format remaining amount (total_cost - total_paid_amount) in FRW */}
      <td style={{ color: '#d9534f', fontWeight: 800 }}>
        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(
          purchase.total_cost - purchase.total_paid_amount
        )}
      </td>

      <td>{purchase.purchase_status}</td>
      
      <td className="d-flex justify-content-center gap-2">
        <Button
          variant="info"
          onClick={() => handleShowDetailModal(purchase.purchase_id)}
        >
          <FaEye style={{ color: 'white', fontSize: '24px', cursor: 'pointer' }} />
        </Button>
        {role === "Administrator" && (
        <Button
          variant="danger"
          onClick={() => handleDeletePurchase(purchase.purchase_id)}
        >
          <VscTrash style={{ color: 'white', fontSize: '24px', cursor: 'pointer' }} />
        </Button>)}
      </td>
    </tr>
  ))}

  {/* Add a row for totals */}
  <tr>
    <td colSpan="4">Total</td>
    
    {/* Total of total_cost */}
    <td style={{ color: '#28a745', fontWeight: 800 }}>
      {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(
        currentPurchases.reduce((total, purchase) => total + parseFloat(purchase.total_cost || 0), 0)
      )}
    </td>
    
    {/* Total of total_paid_amount */}
    <td style={{ color: '#28a745', fontWeight: 800 }}>
      {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(
        currentPurchases.reduce((total, purchase) => total + parseFloat(purchase.total_paid_amount || 0), 0)
      )}
    </td>
    
    {/* Total of remaining amount (total_cost - total_paid_amount) */}
    <td style={{ color: '#d9534f', fontWeight: 800 }}>
      {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(
        currentPurchases.reduce(
          (total, purchase) => total + (parseFloat(purchase.total_cost || 0) - parseFloat(purchase.total_paid_amount || 0)),
          0
        )
      )}
    </td>
    
    <td colSpan="2"></td>
  </tr>
</tbody>

        </Table>
        <div className="d-flex gap-2 justify-content-end mt-3">
        {/* Previous Button */}
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        {/* First Page Button */}
        {totalPages > 3 && currentPage > 3 && (
          <Button
            onClick={() => setCurrentPage(1)}
            className={currentPage === 1 ? "active" : ""}
          >
            1
          </Button>
        )}

        {/* Ellipsis for pages between first page and current page */}
        {totalPages > 3 && currentPage > 4 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Current Page Button */}
        <Button onClick={() => setCurrentPage(currentPage)} className="active">
          {currentPage}
        </Button>

        {/* Ellipsis for pages between current page and last page */}
        {totalPages > 3 && currentPage < totalPages - 3 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Last Page Button */}
        {totalPages > 3 && currentPage < totalPages - 2 && (
          <Button
            onClick={() => setCurrentPage(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </Button>
        )}

        {/* Next Button */}
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
      </div>

      {/* Modal for Purchase Details */}
      <Modal show={showDetailModal} onHide={handleCloseDetailModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Purchase Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPurchase ? (
            <div>
            <div className="d-flex justify-content-between gap-2"> <div><h5>Purchase Information</h5></div><div> <Button className="primary d-flex mb-3" onClick={handleDownload}>
      Download PDF <VscFilePdf/>
      </Button></div></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >
                <p>
                  <strong>Purchase Number:</strong> {selectedPurchase.purchase.purchase_id}
                </p>
                <p>
                  <strong>Ordered By:</strong>{" "}
                  {selectedPurchase.purchase.user_name}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >
                <p>
                  <strong>Supplier Name:</strong>{" "}
                  {selectedPurchase.purchase.supplier_name}
                </p>
                <p>
                  <strong>Date:</strong>{" "}
                  {new Date(
                    selectedPurchase.purchase.purchase_date
                  ).toLocaleDateString()}
                </p>
              </div>
              <Button
                variant="primary"
                onClick={() =>
                  handleShowPaymentHistoryModal(selectedPurchase.purchase.purchase_id)
                }
              >
                View Payment History
              </Button>

              <h5>Details</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedPurchase.details.map((detail) => (
                    <tr key={detail.id}>
                      <td>{detail.name}</td>
                      <td>{detail.req_qty}</td>
                      <td>{new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'RWF',
                }).format(detail.req_unityPrice)}</td>
                      <td>{new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'RWF',
                }).format(detail.req_qty * detail.req_unityPrice)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Total and Paid Amounts Display */}
              <div style={{ marginTop: "15px" }}>
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#f8f9fa",
      borderRadius: "5px",
      border: "1px solid #ced4da"
    }}
  >
    <strong>
      Total Amount:{" "}
      <span style={{ color: "#343a40" }}>
      {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'RWF',
                }).format(
        selectedPurchase.details.reduce(
          (total, detail) =>
            total + detail.req_qty * detail.req_unityPrice,
          0
        ))}
      </span>
    </strong>
  </div>
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#e2e3e5",
      borderRadius: "5px",
      border: "1px solid #ced4da"
    }}
  >
    <strong>
      Paid Amount:{" "}
      <span style={{ color: "#28a745" }}>
      {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'RWF',
                }).format(selectedPurchase.paymentDetails.total_paid || 0)}
      </span>
    </strong>
  </div>
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
      backgroundColor: "#f5c6cb",
      borderRadius: "5px",
      border: "1px solid #ced4da"
    }}
  >
    <strong>
      Remaining Amount:{" "}
      <span style={{ color: "#dc3545" }}>
      {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'RWF',
                }).format(selectedPurchase.details.reduce(
          (total, detail) =>
            total + detail.req_qty * detail.req_unityPrice,
          0
        ) - selectedPurchase.paymentDetails.total_paid)}
      </span>
    </strong>
  </div>
</div>


              {/* Payment Button */}
              <Button variant="success" className="mt-4" disabled={selectedPurchase.details.reduce(
          (total, detail) =>
            total + detail.req_qty * detail.req_unityPrice,
          0
        ) - selectedPurchase.paymentDetails.total_paid===0} onClick={handleShowPaymentModal}>
                Record Payment
              </Button>
            </div>
          ) : (
            <p>Loading details...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDetailModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Payment History Modal */}
      <Modal
        show={showPaymentHistoryModal}
        onHide={handleClosePaymentHistoryModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {paymentHistory.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Payment Method</th>
                  
                  <th>Refence</th><th>Payment Date</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((payment) => (
                  <tr key={payment.pay_id}>
                    <td>{payment.amountpaid}</td>
                    <td>{payment.pay_mode}</td>
                    <td>{payment.reference}</td>
                    <td>
                      {new Date(payment.record_date).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No payment history available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePaymentHistoryModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Recording Payment */}
      <Modal show={showPaymentModal} onHide={handleClosePaymentModal}>
        <Modal.Header closeButton>
          <Modal.Title>Record Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="paymentMethod">
              <Form.Label>Payment Method:</Form.Label>
              <Form.Control
                as="select"
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
                required
              >
                <option value="">Select Payment Method</option>
                {accounts.map((account) => (
                  <option key={account.id} value={account.account_name}>
                    {account.account_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="paymentAmount">
              <Form.Label>Amount:</Form.Label>
              <Form.Control
                type="number"
                value={paymentAmount}
                onChange={handlePaymentAmountChange}
                placeholder="Enter amount"
                min="0"
                step="0.01"
                required
              />
            </Form.Group>
            <Button variant="primary" className="mt-4" onClick={handleRecordPayment}>
              Record Payment
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClosePaymentModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default usersRole (PurchaseItemForm);
