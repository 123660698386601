import React, { useEffect, useState } from "react";
import axios from "../axiosInstance";
import UsersRole from "../UsersRole";
import { Table, Button, Pagination, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const StockOutItems = (role) => {
  const [items, setItems] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "rank", direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Number of items to display per page
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  // Fetch stock out items from API
  useEffect(() => {
    axios.get("/api/stock-out-items")  // Assuming this is the endpoint for your SQL query
      .then((response) => {
        setItems(response.data); // Assuming API response structure matches the SQL result
      })
      .catch((error) => {
        console.error("Error fetching stock out items", error);
        Swal.fire("Error", "Could not fetch stock out items", "error");
      });
  }, []);

  // Handle sorting of the table
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Sorting function
  const sortedItems = [...items]
    .sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

  // Filtering items based on search query
  const filteredItems = sortedItems.filter(item =>
    item.item_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container p-6">
      <h5>Total Items: {filteredItems.length}</h5>
      
      <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '50px'}}>
                      <i className="fas fa-search"></i> 
                    </span>
                  </div>
      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search by item name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Form.Group>
      </div>
      </div>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>#</th> {/* Count column */}
            <th
              className="d-flex justify-content-between gap-2"
              onClick={() => handleSort("item_name")} // Add sort on item_name
            >
              <b>Title</b>
              {/* Add sorting indicator if needed */}
            </th>
            <th onClick={() => handleSort("total_outs")}>
              <b>Total Outs</b>
            </th>
            <th onClick={() => handleSort("rank")}>
              <b>Rank</b>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={item.item_id}>
              <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
              <td>{item.item_name}</td>
              <td>{item.total_outs}</td>
              <td>{item.rank}</td>
              <td>
                <Button variant="info">View</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex gap-2 justify-content-end mt-3">
        {/* Previous Button */}
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        {/* First Page Button */}
        {totalPages > 3 && currentPage > 3 && (
          <Button
            onClick={() => setCurrentPage(1)}
            className={currentPage === 1 ? "active" : ""}
          >
            1
          </Button>
        )}

        {/* Ellipsis for pages between first page and current page */}
        {totalPages > 3 && currentPage > 4 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Current Page Button */}
        <Button onClick={() => setCurrentPage(currentPage)} className="active">
          {currentPage}
        </Button>

        {/* Ellipsis for pages between current page and last page */}
        {totalPages > 3 && currentPage < totalPages - 3 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Last Page Button */}
        {totalPages > 3 && currentPage < totalPages - 2 && (
          <Button
            onClick={() => setCurrentPage(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </Button>
        )}

        {/* Next Button */}
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default UsersRole(StockOutItems);
