import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container, Button, Modal} from "react-bootstrap";
import axios from "../axiosInstance";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { FaUsers} from "react-icons/fa";
import { FaMoneyBillTrendUp, FaMoneyBillTransfer } from "react-icons/fa6";
import { GiTakeMyMoney } from "react-icons/gi";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import mtnImage from "../assets/mtnlogo.png"
import cashImage from "../assets/Money.jpg"
import cssImage from "../assets/zigama-css.png"
import cogeImage from "../assets/cogebanque.png"
import { IoTodayOutline,  IoToday  } from "react-icons/io5";
import { FaArrowsToCircle } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";

// Register required Chart.js components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [role, setRole] = useState(null);
  const [counts, setCounts] = useState({
    users: 0,
    customers: 0,
    suppliers: 0,
    purchases: 0,
    orders: 0,
    balnces: 0,
    mtn: 0,
    cash: 0,
    coge: 0,
    css: 0,
  });
  const [stockValue, setStockValue] = useState(0);
  const [itemsInStock, setItemsInStock] = useState(0);
  const [purchasesData, setPurchasesData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [show, setShow] = useState(false);
  
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Fetch stock value and item count
  useEffect(() => {
    axios
      .get("/api/stockvalue")
      .then((response) => {
        const data = response.data;
        setStockValue(data.stock_value || 0);
        setItemsInStock(data.total_items || 0);
      })
      .catch((error) => {
        console.error("Error fetching stock data:", error);
      });
  }, []);

  // Fetch role and counts
  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);
  }, []);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const [
          usersRes,
          customersRes,
          suppliersRes,
          stockRes,
          stockInRes,
          stockOutRes,
          purchaseRes,
          orderRes,
          balanceRes,
          mtnRes,
          cashRes,
          cogeRes,
          cssRes,
        ] = await Promise.all([
          axios.get("/api/countusers"),
          axios.get("/api/countcustomer"),
          axios.get("/api/countsupliers"),
          axios.get("/api/countstock"),
          axios.get("/api/countinstock"),
          axios.get("/api/countoutstock"),
          axios.get("/api/countpurchase"),
          axios.get("/api/countorder"),
          axios.get("/api/countbalance"),
          axios.get("/api/mtnbalance"),
          axios.get("/api/cashbalance"),
          axios.get("/api/cogebalance"),
          axios.get("/api/cssbalance"),
        ]);

        setCounts({
          users: usersRes.data[0]["COUNT(id)"],
          customers: customersRes.data[0]["COUNT(customer_id)"],
          suppliers: suppliersRes.data[0]["COUNT(supplier_id)"],
          stock: stockRes.data[0]["COUNT(stock_id)"],
          stockin: stockInRes.data[0]["item_in_count"],
          stockout: stockOutRes.data[0]["item_out_count"],
          purchases: purchaseRes.data[0]["COUNT(purchase_id)"],
          orders: orderRes.data[0]["COUNT(order_id)"],
          mtn: mtnRes.data[0]["SUM(balance)"],
          cash: cashRes.data[0]["SUM(balance)"],
          coge: cogeRes.data[0]["SUM(balance)"],
          css: cssRes.data[0]["SUM(balance)"],
          balances: balanceRes.data[0]["SUM(balance)"],
        });
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };

    fetchCounts();
  }, []);

  useEffect(() => {
    // Fetch monthly data for both purchases and orders
    const fetchMonthlyData = async () => {
      try {
        const [purchasesRes, ordersRes] = await Promise.all([
          axios.get("/api/countpurchases"),
          axios.get("/api/countorders"),
        ]);

        setPurchasesData(purchasesRes.data);
        setOrdersData(ordersRes.data);
      } catch (error) {
        console.error("Error fetching monthly data:", error);
      }
    };

    fetchMonthlyData();
  }, []);

  // Helper function to map the monthly data into a full year format (1-12 months)
  const mapDataToFullYear = (data) => {
    const monthsArray = new Array(12).fill(0);
    data.forEach((item) => {
      monthsArray[item.month - 1] = item.total_purchases || item.total_orders;
    });
    return monthsArray;
  };

  // Prepare data for the chart
  const chartData = {
    labels: [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ],
    datasets: [
      {
        label: "Purchases",
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        data: mapDataToFullYear(purchasesData),
      },
      {
        label: "Orders",
        backgroundColor: "rgba(153,102,255,1)",
        borderColor: "rgba(153,102,255,1)",
        borderWidth: 1,
        data: mapDataToFullYear(ordersData),
      },
    ],
  };
  return (
    <Container className={`py-2 mt-2 p-3 ${isMobile ? "mobile-container" : ""}`}>
      <Row className="g-4">
        {role === "Administrator" && (
          <Row className="g-4">
            {/* Users Card */}
            <Col md={4} sm={12} xs={12}>
              <Link to="/users" style={{ textDecoration: 'none' }}>
                <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
                  <Card.Body className="d-flex align-items-center">
                    <FaUsers size={50} className="me-3 text-primary" />
                    <div>
                      <h1 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{counts.users || 0}</h1>
                      <h4 className={`mb-3 ${isMobile ? "fs-5" : ""}`}>All Users</h4>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            {/* Customers Card */}
            <Col md={4} sm={12} xs={12}>
              <Link to="/customers" style={{ textDecoration: 'none' }}>
                <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
                  <Card.Body className="d-flex align-items-center">
                    <FaUsers size={50} className="me-3 text-primary" />
                    <div>
                      <h1 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{counts.customers || 0}</h1>
                      <h4 className={`mb-3 ${isMobile ? "fs-5" : ""}`}>All Customers</h4>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            {/* Suppliers Card */}
            <Col md={4} sm={12} xs={12}>
              <Link to="/suppliers" style={{ textDecoration: 'none' }}>
                <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
                  <Card.Body className="d-flex align-items-center">
                    <FaUsers size={50} className="me-3 text-primary" />
                    <div>
                      <h1 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{counts.suppliers || 0}</h1>
                      <h4 className={`mb-3 ${isMobile ? "fs-5" : ""}`}>All Suppliers</h4>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col md={4} sm={12} xs={12}>
          <Link to="/items-in-stock" style={{ textDecoration: 'none' }}>
            <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
            <Card.Body className="d-flex align-items-center">
              <FaMoneyBillTrendUp size={50} className="me-3 text-primary" />
              <div>
                <h5 className="mb-3 text-primary">{new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'RWF',
                }).format(stockValue)}</h5>
                <h5 className="mb-3 text-primary">{itemsInStock} Items</h5>
                <h4 className={`mb-3 ${isMobile ? "fs-5" : ""}`}>Stock Value</h4>
              </div>
            </Card.Body>
            </Card>
          </Link>
        </Col>
       <Col md={4} sm={12} xs={12}>
       <Button onClick={handleShow} className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px', width: '100%' }}>
         <Card.Body className="d-flex align-items-center">
           <FaMoneyBillTransfer size={50} className="me-3 text-primary" />
           <div>
             <h3 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{new Intl.NumberFormat('en-US', {
               style: 'currency',
               currency: 'RWF',
             }).format(counts.balances || 0)}</h3>
             <h4 className={`mb-3 ${isMobile ? "fs-5" : ""}`}>Accounts Balance</h4>
           </div>
         </Card.Body>
       </Button>
     </Col>
        <Col md={2}>
          <Link to="/purchase_items" style={{ textDecoration: 'none' }}>
            <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
              <Card.Body className="d-flex align-items-center">
                <BiSolidPurchaseTag size={50} className="me-1 text-primary" />
                <div>
                  <h1 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{counts.purchases || 0}</h1>
                  <h6 className={`mb-3 ${isMobile ? "fs-5" : ""}`}>Purchases</h6>
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={2}>
          <Link to="/manage_order" style={{ textDecoration: 'none' }}>
            <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
              <Card.Body className="d-flex align-items-center">
                <GiTakeMyMoney  size={50} className="me-3 text-primary" />
                <div>
                  <h1 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{counts.orders || 0}</h1>
                  <h4 className={`mb-3 ${isMobile ? "fs-5" : ""}`}>sales</h4>
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
         <Col md={12}>
              <Card className="shadow-sm border-0 rounded p-3">
              <Bar
        data={chartData}
        options={{
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }}
      />
              </Card>
            </Col>
          </Row>
        )}

        {/* Manager Role Section */}
        {role === "Manager" && (
          <Col md={6}>
            <Card className="shadow-sm border-0 rounded">
              <Card.Body className="text-center">
                <Card.Title className="text-danger">Manager Dashboard</Card.Title>
                <Card.Text className="display-4">Manager Panel</Card.Text>
                <Card.Text>Oversee Operations, Manage Stock, and Approve Orders.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )}
<Row className="g-4">
          </Row>
        {/* Store Keeper Role Section */}
        {role === "Store Keeper" && (
          
          <Row className="g-4">
         
           <Col md={4} sm={12} xs={12}>
           <Link to="/stocks" style={{ textDecoration: 'none' }}>
             <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
               <Card.Body className="d-flex align-items-center">
                 <FaArrowsToCircle size={50} className="me-3 text-primary" />
                 <div>
                   <h1 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{counts.stock || 0}</h1>
                   <h4 className={`mb-3 ${isMobile ? "fs-5" : ""}`}>Stocks</h4>
                 </div>
               </Card.Body>
             </Card>
           </Link>
         </Col>
           <Col md={4} sm={12} xs={12}>
           <Link to="/items-in-stock" style={{ textDecoration: 'none' }}>
             <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
               <Card.Body className="d-flex align-items-center">
                 <IoToday size={50} className="me-3 text-primary" />
                 <div>
                   <h1 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{counts.stockin || 0}</h1>
                   <h4 className={`mb-3 ${isMobile ? "fs-5" : ""}`}>Item Stock In</h4>
                 </div>
               </Card.Body>
             </Card>
           </Link>
         </Col>
         <Col md={4} sm={12} xs={12}>
           <Link to="/items-out-stock" style={{ textDecoration: 'none' }}>
             <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
               <Card.Body className="d-flex align-items-center">
                 <IoTodayOutline size={50} className="me-3 text-primary" />
                 <div>
                   <h1 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{counts.stockout || 0}</h1>
                   <h4 className={`mb-3 ${isMobile ? "fs-5" : ""}`}>Item Stock Out</h4>
                 </div>
               </Card.Body>
             </Card>
           </Link>
         </Col>
         </Row>
        )}
      </Row>
      <Modal show={show} onHide={handleClose} size="lg" centered>
    <Modal.Header closeButton>
      <Modal.Title>Accounts Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
      <Col md={3}>
                <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
                  <img src={cashImage} alt="MTN Logo" style={{ width: '100%', height: '80px', marginBottom: '10px' }} />
                  <Card.Body className="d-flex align-items-center">
                    <h5 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'RWF',
                    }).format(counts.cash || 0)}</h5>
                    
                  </Card.Body>
                </Card>
              </Col>
      <Col md={3}>
                <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
                  <img src={mtnImage} alt="MTN Logo" style={{ width: '100%', height: '80px', marginBottom: '10px' }} />
                  <Card.Body className="d-flex align-items-center">
                    <h5 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'RWF',
                    }).format(counts.mtn || 0)}</h5>
                    
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
                  <img src={cssImage} alt="MTN Logo" style={{ width: '100%', height: '95px', marginBottom: '5px' }} />
                  <Card.Body className="d-flex align-items-center">
                    <h5 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'RWF',
                    }).format(counts.css || 0)}</h5>
                    
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="shadow-sm border-0 rounded" style={{ backgroundColor: '#f1f9ff', height: '150px' }}>
                  <img src={cogeImage} alt="MTN Logo" style={{ width: '100%', height: '80px', marginBottom: '10px' }} />
                  <Card.Body className="d-flex align-items-center">
                    <h5 className={`mb-0 ${isMobile ? "fs-3" : ""} text-primary`}>{new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'RWF',
                    }).format(counts.coge || 0)}</h5>
                    
                  </Card.Body>
                </Card>
              </Col>

      </Row>

    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
    </Container>
    
  );
};
const mobileCardStyle = {
  backgroundColor: "#f1f9ff",
  height: "120px",
};

const cardStyle = {
  backgroundColor: "#f1f9ff",
  height: "150px",
};
export default Dashboard;
