import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import axios from "../axiosInstance";
import Swal from "sweetalert2";

const AddUserModal = ({ show, handleClose, refreshUsers, isEdit, user }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [roles, setRoles] = useState([]);
  const [role_id, setRoleId] = useState("");

  useEffect(() => {
    if (isEdit && user) {
      // Pre-fill form for editing
      setName(user.name);
      setEmail(user.email);
      setPassword("");  // Clear the password field for security reasons
    } else {
      // Clear the form for adding a new user
      setName("");
      setEmail("");
      setPassword("");
    }
  }, [isEdit, user, show]);

  useEffect(() => {
    axios.get("/api/roles")
      .then((response) => setRoles(response.data))
      .catch((error) => console.error("Error fetching roles:", error));
  }, []);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const handleSaveUser = async () => {
    try {
      if (isEdit) {
        // Update user
        await axios.put(`/api/users/${user.id}`, { name, role_id, email, password });
        Swal.fire("Success", "User updated successfully", "success");
      } else {
        // Add new user
        await axios.post("/api/users", { name, role_id, email, password });
        Swal.fire("Success", "User added successfully", "success");
      }
      handleClose();
      refreshUsers();
    } catch (error) {
      Swal.fire("Error", isEdit ? "Failed to update user" : "Failed to add user", "error");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Edit User" : "Add New User"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={handleEmailChange}
            isInvalid={!isEmailValid}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mt-4">
          <Form.Label>Role</Form.Label>
          <Form.Control
            as="select"
            value={role_id}
            onChange={(e) => setRoleId(e.target.value)}
            required
          >
            {roles.map((role) => (
              <option key={role.role_id} value={role.role_id}>
                {role.role_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSaveUser}
          disabled={!isEmailValid || !name || !password}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
// git config user.name "tuyizereolivier102"
// git config user.email "tuyizereolivier102@gmail.com"

const UserManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);

  useEffect(() => {
    // Fetch data for users
    const fetchUsers = async () => {
      try {
        const usersRes = await axios.get("/api/users");
        setUsers(usersRes.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const refreshUsers = async () => {
    try {
      const usersRes = await axios.get("/api/users");
      setUsers(usersRes.data);
    } catch (error) {
      console.error("Error refreshing users:", error);
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEdit(true);
    setShowModal(true);
  };

  const handleDeleteUser = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/api/users/${id}`);
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "User has been deleted.",
          });
          refreshUsers(); // Refresh users list
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was an error deleting the user!",
          });
        }
      }
    });
  };

  const filteredUsers = users.filter((user) =>
    user.role_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination
  const indexOfLastItem = currentPage * usersPerPage;
  const indexOfFirstItem = indexOfLastItem - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  return (
    <div className="container">
      <Button className="mb-3"
        variant="primary"
        onClick={() => {
          setSelectedUser(null);
          setIsEdit(false);
          setShowModal(true);
        }}
      >
        Add New User
      </Button>

      <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '50px'}}>
                      <i className="fas fa-search"></i> 
                    </span>
                  </div>
      <Form.Control
        type="text"
        placeholder="Search .........."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      </div>
      </div>

      {/* Table of Users */}
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.role_name}</td>
              <td>
                <Button
                  variant="warning"
                  className="me-2"
                  onClick={() => handleEditUser(user)}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteUser(user.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <div className="d-flex gap-2 justify-content-end mt-3">
        {/* Previous Button */}
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        {/* First Page Button */}
        {totalPages > 3 && currentPage > 3 && (
          <Button
            onClick={() => setCurrentPage(1)}
            className={currentPage === 1 ? "active" : ""}
          >
            1
          </Button>
        )}

        {/* Ellipsis for pages between first page and current page */}
        {totalPages > 3 && currentPage > 4 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Current Page Button */}
        <Button onClick={() => setCurrentPage(currentPage)} className="active">
          {currentPage}
        </Button>

        {/* Ellipsis for pages between current page and last page */}
        {totalPages > 3 && currentPage < totalPages - 3 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Last Page Button */}
        {totalPages > 3 && currentPage < totalPages - 2 && (
          <Button
            onClick={() => setCurrentPage(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </Button>
        )}

        {/* Next Button */}
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
      {/* Modal for Adding/Editing User */}
      <AddUserModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        refreshUsers={refreshUsers}
        isEdit={isEdit}
        user={selectedUser}
      />
    </div>
  );
};

export default UserManagement;
