import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../components/all.css";
import "../components/css/style.css";
import "../components/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import StocksManagement from "../pages/StockManagement";
function Stocks() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };
  return (
    <div className="d-flex vh-100 w-100">
      <Sidebar userId={localStorage.getItem("userId")} isSidebarOpen={isSidebarOpen} />
      <div className="content flex-grow-1 d-flex flex-column">
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="flex-grow-1 mt-4 p-4">
        <StocksManagement />
      </div>
    </div>
    </div>
  );
}

export default Stocks;
