import React, { useEffect, useState } from "react";
import axios from "../axiosInstance";
import { Table, Button, Form, Modal, Row, Col } from "react-bootstrap";
import {
  FaChevronCircleDown,
  FaTrashAlt,
  FaPen,
  FaUserPlus,
} from "react-icons/fa";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { VscCloudUpload } from "react-icons/vsc";

const SuppliersManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [newSupplierId, setNewSupplierId] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [editSupplier, setEditSupplier] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [suppliersPerPage] = useState(10);
  const [editItem, seteditItem] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchSuppliers = async () => {
      const suppliersRes = await axios.get("/api/suppliers");
      setSuppliers(suppliersRes.data);
    };
    fetchSuppliers();
  }, []);
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);
  useEffect(() => {
    axios
      .get("/api/suppliers/max-id")
      .then((response) => {
        const maxId = response.data.maxsupplierId || 0;
        setNewSupplierId(maxId + 1);
      })
      .catch((error) => {
        console.error("Error fetching max supplier ID:", error);
      });
  }, [showModal]);
  const fetchSuppliers = async () => {
    try {
      const response = await axios.get("/api/suppliers");
      setSuppliers(response.data);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  };
  const handleAddSupplier = async () => {
    try {
      // Check if all fields are filled
      if (!fname || !lname || !phone || !email || !address) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please Fill All Fields",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
  
      // Make the POST request to add the supplier
      const response = await axios.post("/api/suppliers", {
        supplier_id: newSupplierId,
        fname,
        lname,
        phone,
        email,
        address,
        deleted,
      });
  
      // Show success alert
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
  
      // Reset form fields
      setFname("");     // Reset first name
      setLname("");     // Reset last name
      setPhone("");     // Reset phone
      setEmail("");     // Reset email
      setAddress("");   // Reset address
  
      setShowModal(false);  // Close the modal
      fetchSuppliers();     // Refresh supplier list
    } catch (error) {
      // Show error alert
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error adding the Supplier!",
      });
    }
  };
  

  const handleDeleteSupplier = async (supplier_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this  Supplier Permanently!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/api/suppliers/${supplier_id}`);
          Swal.fire({
            icon: "success",
            title: "Deleted",
            text: "Supplier has been deleted.",
          });
          fetchSuppliers();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was an error deleting the suppliers!",
          });
        }
      }
    });
  };
  const handleUpdateSupplier = async () => {
    try {
      // Ensure `editSupplier` has the correct properties
      const response = await axios.put(
        `/api/suppliers/${editSupplier.supplier_id}`,
        {
          fname: editSupplier.fname,
          lname: editSupplier.lname,
          phone: editSupplier.phone,
          email: editSupplier.email,
          address: editSupplier.address,
          deleted: editSupplier.deleted,
        }
      );

      Swal.fire({
        icon: "success",
        title: "Updated",
        text: response.data.message,
      });

      setEditModal(false);
      fetchSuppliers(); // Fetch the updated list of suppliers
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error updating the supplier!",
      });
    }
  };

  const handleSort = (field) => {
    setSortedField(field);
    const sortedData = [...suppliers].sort((a, b) => {
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
      return 0;
    });
    setSuppliers(sortedData);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    if (!file) {
      Swal.fire({
        icon: "warning",
        title: "No file selected",
        text: "Please select an Excel file to upload.",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/api/suppliers/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      fetchSuppliers();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error uploading the file!",
      });
    }
  };
  const filteredsuppliers = suppliers.filter((supplier) =>
    supplier.fname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination based on the filtered suppliers
  const indexOfLastItem = currentPage * suppliersPerPage;
  const indexOfFirstItem = indexOfLastItem - suppliersPerPage;
  const currentsuppliers = filteredsuppliers.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredsuppliers.length / suppliersPerPage);

  return (
    <div className="container mt-5 p-6">
      <br />
      <div className="d-flex justify-content-between gap-2 align-suppliers-center mb-3">
        <div>
          <Button variant="primary" onClick={() => setShowModal(true)}>
            <div className="d-flex gap-2 ">
              <FaUserPlus />
              <b>Add New Supplier</b>
            </div>
          </Button>
        </div>
        <div>
          <Form className="d-flex gap-2">
            <Form.Group className="mb-3">
              <Form.Label>
                <b>Upload Excel File</b>
              </Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button
              className="mt-4 align-suppliers-center"
              variant="primary"
              onClick={handleUpload}
              style={{
                height: "45px",
                width: "40px",
                padding: "5px",
              }}
            >
              {"  "} <VscCloudUpload />
            </Button>
          </Form>
        </div>
      </div>
      {/* Search Input */}
      <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '50px'}}>
                      <i className="fas fa-search"></i> 
                    </span>
                  </div>
      <Form.Control
        type="text"
        placeholder="Search .........."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      </div>
      </div>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th onClick={() => handleSort("fname")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Names</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th onClick={() => handleSort("phone")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Phone</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th onClick={() => handleSort("email")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Email</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th onClick={() => handleSort("address")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Address</b>
                <FaChevronCircleDown />
              </div>
            </th>

            <th onClick={() => handleSort("deleted")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Status</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentsuppliers.map((supplier) => (
            <tr key={supplier.supplier_id}>
              <td>{supplier.supplier_id}</td>
              <td>
                {supplier.fname}-{supplier.lname}
              </td>

              <td>{supplier.phone}</td>
              <td>{supplier.email}</td>
              <td>{supplier.address}</td>
              <td>
                {supplier.deleted === 1 ? (
                  <span style={{ color: "red" }}>Inactive</span>
                ) : (
                  <span style={{ color: "green" }}>Active</span>
                )}
              </td>
              <td>
                {/* Edit button always visible */}
                <Button
                  variant="warning"
                  onClick={() => {
                    setEditSupplier(supplier);
                    setEditModal(true);
                  }}
                >
                  <FaPen />
                </Button>

                {/* Delete button only visible if not deleted */}
                {supplier.deleted === 0 && (
                  <Button
                    variant="danger"
                    className="ms-2"
                    onClick={() => handleDeleteSupplier(supplier.supplier_id)}
                  >
                    <FaTrashAlt />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <div className="d-flex gap-2 justify-content-end mt-3">
        {/* Previous Button */}
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        {/* First Page Button */}
        {totalPages > 3 && currentPage > 3 && (
          <Button
            onClick={() => setCurrentPage(1)}
            className={currentPage === 1 ? "active" : ""}
          >
            1
          </Button>
        )}

        {/* Ellipsis for pages between first page and current page */}
        {totalPages > 3 && currentPage > 4 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Current Page Button */}
        <Button onClick={() => setCurrentPage(currentPage)} className="active">
          {currentPage}
        </Button>

        {/* Ellipsis for pages between current page and last page */}
        {totalPages > 3 && currentPage < totalPages - 3 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Last Page Button */}
        {totalPages > 3 && currentPage < totalPages - 2 && (
          <Button
            onClick={() => setCurrentPage(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </Button>
        )}

        {/* Next Button */}
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
      {/* Add Supplier Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Supplier ID</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={newSupplierId || ""}
                placeholder="Loading Supplier ID..."
              />
            </Form.Group>

            {/* Use Grid System for Two Fields on One Line */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Another Row for Phone and Email */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Address and Deleted Checkbox */}
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Deleted"
                checked={deleted}
                onChange={(e) => setDeleted(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddSupplier}>
            Add Supplier
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Supplier Modal */}
      <Modal show={editModal} onHide={() => setEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Row for First Name and Last Name */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={editSupplier?.fname || ""}
                    onChange={(e) =>
                      setEditSupplier({
                        ...editSupplier,
                        fname: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={editSupplier?.lname || ""}
                    onChange={(e) =>
                      setEditSupplier({
                        ...editSupplier,
                        lname: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Row for Phone and Email */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    value={editSupplier?.phone || ""}
                    onChange={(e) =>
                      setEditSupplier({
                        ...editSupplier,
                        phone: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={editSupplier?.email || ""}
                    onChange={(e) =>
                      setEditSupplier({
                        ...editSupplier,
                        email: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Row for Address and Status */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={editSupplier?.address || ""}
                    onChange={(e) =>
                      setEditSupplier({
                        ...editSupplier,
                        address: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={editSupplier?.deleted || ""}
                    onChange={(e) =>
                      setEditSupplier({
                        ...editSupplier,
                        deleted: parseInt(e.target.value),
                      })
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="0">Active</option>
                    <option value="1">Inactive</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateSupplier}>
            Update Supplier
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SuppliersManagement;
